import React from "react";
import { useParams } from "react-router";

import { NavigationBar } from "../components/app/NavigationBar";
import { LoginPage } from "../components/login/LoginPage";

import { AlertBody, AlertHeader, ErrorContainer, Link } from "./LoginRoute";

export const InvalidLinkRoute: React.ComponentType = React.memo(
  function LinkRoute() {
    return (
      <>
        <NavigationBar isInApp={false} />
        <LoginPage embedded={true}>
          <ErrorContainer>
            <AlertHeader>Oops!</AlertHeader>
            <AlertBody>That workspace invite is invalid or expired.</AlertBody>
          </ErrorContainer>
        </LoginPage>
      </>
    );
  },
);

export const WrongUserLinkRoute: React.ComponentType = React.memo(
  function LinkRoute() {
    const { key } = useParams<{ key: string }>();
    return (
      <>
        <NavigationBar isInApp={false} />
        <LoginPage embedded={true}>
          <ErrorContainer>
            <AlertHeader>
              Oops! You&apos;re already logged into this workspace under another
              email.
            </AlertHeader>
            <AlertBody>
              To join this workspace, please{" "}
              <Link href={`/link/${key}/logout`} target="_blank">
                Log out and try again
              </Link>
              .
            </AlertBody>
          </ErrorContainer>
        </LoginPage>
      </>
    );
  },
);

export const LoggedInLinkRoute: React.ComponentType = React.memo(
  function LinkRoute() {
    const { key } = useParams<{ key: string }>();
    return (
      <>
        <NavigationBar isInApp={false} />
        <LoginPage embedded={true}>
          <ErrorContainer>
            <AlertHeader>
              Oops! You’ve already joined this workspace.
            </AlertHeader>
            <AlertBody>
              You&apos;ve clicked on an invitation link, but you&apos;re already
              a part of this workspace.{" "}
              <Link href={`/link/${key}/login`}>Take me to my workspace</Link>.
            </AlertBody>
          </ErrorContainer>
        </LoginPage>
      </>
    );
  },
);
