import {
  AppSessionCellId,
  CellId,
  CellReferencesV2,
  GraphNodeV3,
  MonacoLanguageType,
  ParameterOutputType,
  UPDATE_CODE_CELL,
  UPDATE_MARKDOWN_CELL,
  UPDATE_SQL_CELL,
  convertJinjaSqlReferences,
  mergeStoredCellReferencesV3,
  stableEmptyArray,
  upgradeCellReferencesV2,
  uuid,
} from "@hex/common";
import { editor as Editor, IDisposable, Uri } from "monaco-editor";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { shallowEqual } from "react-redux";
import { createSelector } from "reselect";

import { ScopeItemFragment } from "../../appsession-multiplayer/AppSessionMPModel.generated";
import { useScopeSelector } from "../../appsession-multiplayer/state-hooks/scopeStateHooks";
import { PARAMETER_COMPLETION_PROVIDER } from "../../components/cell/monaco/ParameterCompletionProvider";
import { useCellData } from "../../components/output/useCellData";
import { useGraphNodeV3Selector } from "../../graph/graphNodeV3Hooks";
import {
  useCellContentsGetter,
  useCellContentsSelector,
} from "../../hex-version-multiplayer/state-hooks/cellContentsStateHooks";
import { useHexVersionSelector } from "../../hex-version-multiplayer/state-hooks/hexVersionStateHooks";
import { useUpdateLock } from "../../hooks/locks/useLock";
import { useDebouncedCallback } from "../../hooks/useDebouncedCallback";
import { useShouldSuppressUndefinedReferences } from "../../hooks/useErroredCells";
import { useSelector } from "../../redux/hooks.js";
import { CellContentsMP, CellMP } from "../../redux/slices/hexVersionMPSlice";
import { selectProjectSearchState } from "../../redux/slices/logicViewSlice.js";
import { useHexVersionAOContext } from "../../util/hexVersionAOContext";
import { useHexFlag } from "../../util/useHexFlags.js";

import { HighlightContext, updateHighlights } from "./updateHighlights";

// Non-serializable state shouldn't go in Redux, so keeps models outside of it.
const modelsByCellId: Record<string, Editor.ITextModel | undefined> = {};
const cellIdsByModelURI: Record<string, CellId | undefined> = {};
const highlightsByCellId: Record<string, string[] | undefined> = {};
const lastSavedSourceByCellId: Record<string, string> = {};
// used to figure out if we need to GC a given model
const numberOfWatchersByCellId: Record<string, number | undefined> = {};

export const getModel = (cellId: CellId): Editor.ITextModel | undefined => {
  return modelsByCellId[cellId];
};

export const getCellIdForModel = (
  model: Editor.ITextModel,
): CellId | undefined => cellIdsByModelURI[model.uri.toString()];

export const getOrCreateModel = (cellId: CellId): Editor.ITextModel => {
  let model = modelsByCellId[cellId];
  if (model == null) {
    model = Editor.createModel(
      "",
      // this will change for the model eventually,
      // but monaco runs a slow function to guess the language if we don't provide one here.
      "plaintext",
      // make sure that the model gets a unique uuid that
      // is not an incrementing number
      Uri.from({ scheme: "inmemory", authority: "model", path: `/${uuid()}` }),
    );
    modelsByCellId[cellId] = model;
    cellIdsByModelURI[model.uri.toString()] = cellId;
  }
  return model;
};

function useLastSavedSource(
  cellId: string,
  source: string,
): [string, (source: string) => void] {
  const isInitialized = useRef(false);

  const setLastSavedSource = useCallback(
    (lastSavedSource) => {
      lastSavedSourceByCellId[cellId] = lastSavedSource;
    },
    [cellId],
  );

  if (!isInitialized.current) {
    setLastSavedSource(source);
    isInitialized.current = true;
  }
  return [lastSavedSourceByCellId[cellId] ?? "", setLastSavedSource];
}

/**
 * This returns a monaco model with read only contents for a given cell.
 *
 * The model's contents will only be up to date with the source only if
 * `useWritableModel` is currently mounted somewhere else.
 */
export const useModel = (cellId: CellId): Editor.ITextModel => {
  const model = getOrCreateModel(cellId);

  // We increment the number of watchers in useLayoutEffect because it's called before useEffect.
  // If we need two models for the same cellId (ie a component that calls useModel is unmounted and remounted by its parent),
  // we need to increment the total count of usages of the model for the cellId before the garbage collection cleans up the
  // usage that is being unmounted. Otherwise, the newly-accessed instance of the model will be disposed and unusable.
  useLayoutEffect(() => {
    const mountNumberOfWatchers = numberOfWatchersByCellId[cellId] ?? 0;
    numberOfWatchersByCellId[cellId] = mountNumberOfWatchers + 1;
  }, [cellId]);

  useEffect(() => {
    return () => {
      const unmountNumberOfWatchers = numberOfWatchersByCellId[cellId] ?? 0;
      numberOfWatchersByCellId[cellId] = unmountNumberOfWatchers - 1;

      // garabage collect old models
      if (numberOfWatchersByCellId[cellId] === 0) {
        const modelToDispose = modelsByCellId[cellId];
        if (modelToDispose) {
          delete cellIdsByModelURI[modelToDispose.uri.toString()];
          modelToDispose.dispose();
        }
        delete modelsByCellId[cellId];
        delete numberOfWatchersByCellId[cellId];
      }
    };
  }, [cellId]);

  return model;
};

// If you change this value, be sure to test out a larger notebook
// to make sure there isn't any typing lag as a result
const UPDATE_CODE_DEBOUNCE = 500;

// this time is deliberately different than UPDATE_CODE_DEBOUNCE to avoid
// doing too much work at once.
const UPDATE_HIGHLIGHT_DELAY = 450;

export type useWritableModelResult = {
  model: Editor.ITextModel;
  updateHighlights: () => void;
};

/**
 * Returns the monaco model for a given cell with mutable content.
 * This hook should only be mounted _once_ per cell id at a given time.
 */
export const useWritableModel = ({
  appSessionCellId,
  cellId,
  language,
  source,
}: {
  cellId: CellId;
  appSessionCellId: AppSessionCellId | undefined;
  source: string;
  language: MonacoLanguageType;
}): useWritableModelResult => {
  const { dispatchAO } = useHexVersionAOContext();
  const { acquireLock } = useUpdateLock({
    canUnlock: false,
    cellId,
  });
  const { state } = useCellData({ appSessionCellId });
  const suppressUndefinedReferences = useShouldSuppressUndefinedReferences(
    cellId,
    state,
  );
  const getCellContents = useCellContentsGetter();

  const model = useModel(cellId);
  const setHighlights = useCallback(
    (cb: (prevHighlights: string[]) => string[]): void => {
      const prevHighlights = highlightsByCellId[cellId] || [];
      const newHighlights = cb(prevHighlights);
      highlightsByCellId[cellId] = newHighlights;
    },
    [cellId],
  );

  const [lastSavedSource, setLastSavedSource] = useLastSavedSource(
    cellId,
    source,
  );

  const getCompletionId = useCallback(
    (name: string) => {
      return `${cellId}-${name}`;
    },
    [cellId],
  );

  const graphV3 = useHexVersionSelector({ selector: (hv) => hv.graphV3 });

  const newParams = useCellContentsSelector({
    cellId,
    selector: (cellContentsState: CellContentsMP) => {
      if (graphV3 && cellContentsState.__typename === "SqlCell") {
        return mergeStoredCellReferencesV3(
          cellContentsState.sqlCellReferencesV3,
          cellContentsState.jinjaCellReferencesV3,
        ).newParams.map(({ param }) => param);
      }
      if (
        (cellContentsState.__typename === "CodeCell" ||
          cellContentsState.__typename === "SqlCell" ||
          cellContentsState.__typename === "MarkdownCell") &&
        cellContentsState.cellReferencesV2 != null
      ) {
        return cellContentsState.cellReferencesV2.newParams.map(
          ({ param }) => param,
        );
      }
      return [];
    },
    equalityFn: shallowEqual,
  });

  const context: HighlightContext = useCellContentsSelector({
    cellId,
    selector: (cellContentsState: CellContentsMP) => {
      if (cellContentsState.__typename === "SqlCell") {
        if (cellContentsState.dataFrameCell) {
          return "df_sql";
        }
        return "db_sql";
      }
      return "code";
    },
  });

  useEffect(() => {
    const completionIds: string[] = [];
    const completionItems: Parameters<
      typeof PARAMETER_COMPLETION_PROVIDER.addCompletions
    >[0] = [];
    newParams.forEach((param) => {
      const completionId = getCompletionId(param);
      completionIds.push(completionId);
      completionItems.push({
        id: completionId,
        completion: {
          name: param,
          outputType: ParameterOutputType.DYNAMIC,
          // TODO: show preview of the line of code that defined the param
        },
      });
    });
    PARAMETER_COMPLETION_PROVIDER.addCompletions(completionItems);
    return () => {
      PARAMETER_COMPLETION_PROVIDER.removeCompletions(completionIds);
    };
  }, [getCompletionId, newParams]);

  const updateCellSource = useCallback(
    (newSource: string) => {
      setLastSavedSource(newSource);
      const contents = getCellContents(cellId);
      if (contents.__typename === "CodeCell") {
        dispatchAO(
          UPDATE_CODE_CELL.create({
            codeCellId: contents.codeCellId,
            cellId,
            key: "source",
            value: newSource,
          }),
          { skipUndoBuffer: true },
        );
      } else if (contents.__typename === "MarkdownCell") {
        dispatchAO(
          UPDATE_MARKDOWN_CELL.create({
            markdownCellId: contents.markdownCellId,
            cellId,
            key: "source",
            value: newSource,
          }),
          { skipUndoBuffer: true },
        );
      } else if (contents.__typename === "SqlCell") {
        dispatchAO(
          UPDATE_SQL_CELL.create({
            sqlCellId: contents.sqlCellId,
            cellId,
            key: "source",
            value: newSource,
          }),
          { skipUndoBuffer: true },
        );
      }
    },
    [cellId, dispatchAO, getCellContents, setLastSavedSource],
  );

  const throttledAcquireLock = useDebouncedCallback(acquireLock, 30 * 1000, {
    leading: true,
    maxWait: 30 * 1000,
  });

  const debouncedUpdateCell = useDebouncedCallback(
    updateCellSource,
    UPDATE_CODE_DEBOUNCE,
  );

  const cellReferencesSelector = useMemo(
    () =>
      createSelector(
        (cellContentsState: CellContentsMP) => cellContentsState,
        (cellContentsState: CellContentsMP): readonly CellReferencesV2[] => {
          if (graphV3 && cellContentsState.__typename === "SqlCell") {
            const refs = [];
            let sqlRefs = cellContentsState.sqlCellReferencesV3;
            let jinjaRefs = cellContentsState.jinjaCellReferencesV3;
            if (cellContentsState.jinjaSqlReferences != null) {
              [sqlRefs, jinjaRefs] = convertJinjaSqlReferences(
                cellContentsState.jinjaSqlReferences,
                new Map(), // we don't highlight dynamic table references so we can omit these
              );
            }
            if (sqlRefs != null) {
              refs.push(sqlRefs);
            }
            if (jinjaRefs != null) {
              refs.push(jinjaRefs);
            }
            return refs;
          }
          // don't return references if there is a parse error, as the source locations might be out of date
          if (
            "cellReferencesParseError" in cellContentsState &&
            cellContentsState.cellReferencesParseError != null
          ) {
            return stableEmptyArray();
          }
          if (
            "cellReferencesV2" in cellContentsState &&
            cellContentsState.cellReferencesV2 != null
          ) {
            return [cellContentsState.cellReferencesV2];
          }
          return stableEmptyArray();
        },
      ),
    [graphV3],
  );
  const cellReferencesArray = useCellContentsSelector({
    cellId,
    selector: cellReferencesSelector,
    equalityFn: shallowEqual,
  });

  const graphNode = useGraphNodeV3Selector({
    nodeId: cellId,
    selector: (graphNodeState: GraphNodeV3<CellMP> | undefined) =>
      graphNodeState,
    equalityFn: shallowEqual,
    safe: true,
  });

  const refParams = useMemo(() => {
    if (graphNode?.type === "cell") {
      return new Set([
        ...graphNode.inputParams.keys(),
        ...graphNode.outputParams.keys(),
      ]);
    }
    if (!cellReferencesArray) {
      return new Set();
    }
    return new Set(
      cellReferencesArray.flatMap((cellReferences) =>
        [
          ...(cellReferences?.newParams ?? []),
          ...(cellReferences?.referencedParams ?? []),
        ].map((p) => p.param),
      ),
    );
  }, [cellReferencesArray, graphNode]);

  const refScopeSelector = useCallback(
    (scope: Record<string, ScopeItemFragment | undefined>) => {
      return Object.fromEntries(
        Object.entries(scope).filter(([key]) => refParams.has(key)),
      );
    },
    [refParams],
  );

  const refScopes = useScopeSelector({
    selector: refScopeSelector,
    equalityFn: shallowEqual,
  });

  const unidfQueryMode = useHexFlag("unidf-query-mode");
  const projectSearchState = useSelector(selectProjectSearchState);

  const updateHighlightsCallback = useCallback((): void => {
    if (model != null) {
      setHighlights((prevHighlights) =>
        updateHighlights({
          cellReferencesArray: cellReferencesArray.map((c) =>
            upgradeCellReferencesV2(c, null),
          ),
          graphNode: graphNode,
          refScopes,
          model,
          language,
          oldHighlights: prevHighlights,
          context,
          suppressUndefinedReferences,
          unidfQueryMode,
          projectSearchState,
          cellId,
        }),
      );
    }
  }, [
    cellId,
    model,
    setHighlights,
    cellReferencesArray,
    graphNode,
    refScopes,
    language,
    context,
    suppressUndefinedReferences,
    unidfQueryMode,
    projectSearchState,
  ]);

  const debouncedUpdateHighlightsCallback = useDebouncedCallback(
    updateHighlightsCallback,
    UPDATE_HIGHLIGHT_DELAY,
  );

  useEffect(() => {
    Editor.setModelLanguage(model, language);
    updateHighlightsCallback();
  }, [language, model, updateHighlightsCallback]);

  const modelListenerFn = useCallback(
    ({ eol }: Editor.IModelContentChangedEvent) => {
      throttledAcquireLock();
      // R kernels have issues parsing windows style EOL (CRLF) so we force LF
      // unfortunately, there is no way to configure this change permanently for a model
      // so we have to check after everychange to see if monaco has auto changed EOL to CRLF
      // https://github.com/microsoft/monaco-editor/issues/2019
      if (eol === "\r\n") {
        // we use set instead of push so that this change does not end up on monaco's
        // undo stack and cause head aches.
        model.setEOL(Editor.EndOfLineSequence.LF);
      }
      debouncedUpdateCell(model.getValue());
      debouncedUpdateHighlightsCallback();
    },
    [
      debouncedUpdateCell,
      debouncedUpdateHighlightsCallback,
      model,
      throttledAcquireLock,
    ],
  );

  // Track the disposable of the onDidChangeContent listener so we can dispose of it when the component unmounts
  const didChangeContentRef = useRef<IDisposable | null>(null);

  useEffect(() => {
    return () => {
      // Always clear the reference to the listener when the component unmounts
      didChangeContentRef.current?.dispose();
      didChangeContentRef.current = null;
    };
  }, []);

  // Every time the model changes, swap the old listener for a new one, disposing the old one.
  useEffect(() => {
    const oldListener = didChangeContentRef.current;
    const newListener = model.onDidChangeContent(modelListenerFn);
    if (oldListener != null) {
      oldListener.dispose();
    }

    didChangeContentRef.current = newListener;
  }, [modelListenerFn, model]);

  const setModelValue = useCallback(
    (newValue: string, silent?: boolean) => {
      if (model.isDisposed()) {
        // TODO: should we log
        return;
      }
      if (silent) {
        // we need to dispose of the listener before we set the value, so we don't trigger it
        if (didChangeContentRef.current != null) {
          didChangeContentRef.current.dispose();
        }
        model.setValue(newValue);
        updateHighlightsCallback();
        didChangeContentRef.current = model.onDidChangeContent(modelListenerFn);
      } else {
        model.setValue(newValue);
      }
    },
    [updateHighlightsCallback, model, modelListenerFn],
  );

  useEffect(() => {
    setModelValue(source, true);
    // we DO NOT want this effect to fire whenever source changes
    // ONLY whenever our model changes (e.g. language changes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  // keep the model in sync with the upstream source value
  // but only if it does not match what we expect
  useEffect(() => {
    if (lastSavedSource !== source) {
      setModelValue(source, true);
      // display updates that originate from outside the monaco editor
      // (i.e. server corrections)
      setLastSavedSource(source);
    }
    // we DO NOT want this effect to fire whenever last saved source changes
    // ONLY when source changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, setModelValue]);

  useEffect(() => {
    // Check for tokenization and do intial highlight
    // gross hack because of https://github.com/microsoft/monaco-editor/issues/115
    // Wait until tokenization is ready
    let waitedSoFar = 0;
    const interval = 250;
    // If we time out here, colorization of parameters won't happen until the user changes the source
    // Try this for a maximum of 2 seconds
    const maxWait = 2000;
    const timeout = setInterval(() => {
      waitedSoFar += interval;
      if (waitedSoFar > maxWait) {
        clearInterval(timeout);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((model as any)._tokenization?._tokenizationSupport) {
        clearInterval(timeout);
        updateHighlightsCallback();
      }
    }, interval);

    return () => {
      clearInterval(timeout);
    };
  }, [updateHighlightsCallback, model]);

  return { model, updateHighlights: debouncedUpdateHighlightsCallback };
};
