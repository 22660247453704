import { OrgRole, encodeQueryParams } from "@hex/common";
import React from "react";
import { Redirect } from "react-router";

import { useAttribution } from "../../hooks/useAttribution";
import { ORG_ID } from "../../orgs";
import { useOrgsClientQuery } from "../../orgs.generated";
import { OrgAwareRedirect } from "../../util/OrgAwareRedirect";
import { useMeQuery } from "../HexRoutes.generated";
import { Routes, useDictQueryParams } from "../routes";

const NewProjectRouteRedirect: React.ComponentType = React.memo(
  function NewProjectRouteRedirect() {
    const { data, error: meError, loading: meLoading } = useMeQuery();
    const {
      data: clientOrgs,
      error: clientOrgsError,
      loading: clientOrgsLoading,
    } = useOrgsClientQuery();

    const attribution = useAttribution();
    const queryParams = useDictQueryParams();

    const { signup, ...params } = queryParams;
    const redirectToSignup = signup === "true";

    const isAnonymousUser = !data?.me || data.me.orgRole === OrgRole.ANONYMOUS;

    const isAuthenticated =
      clientOrgs && clientOrgs?.orgsClient.orgs.length > 0;

    const loading =
      meLoading || clientOrgsLoading || !clientOrgs?.orgsClient.loaded;

    if (loading) {
      return null;
    }
    // TODO(VELO-4725): Move this redirect to the server side
    // If user is authenticated into org, redirect to org specific route
    if (!meError && !isAnonymousUser) {
      return (
        <OrgAwareRedirect
          toUrl={Routes.href(ORG_ID, false, Routes.NEW_PROJECT, {
            attribution,
            params,
          })}
        />
      );
    }
    // If user is authenticated into another org, redirect to workspace picker
    // or first authenticated org.
    if (!clientOrgsError && isAuthenticated) {
      // if authenticated into multiple orgs, redirect to login workspace picker
      if (clientOrgs.orgsClient.orgs.length > 1) {
        return (
          <Redirect
            to={Routes.WORKSPACE_PICKER.getUrl({
              subview: "login",
              attribution,
              params: {
                redirectTo: `${window.location.pathname}${encodeQueryParams(
                  params,
                )}`,
              },
            })}
          />
        );
      }

      // if authenticated into one org, redirect directly to org
      const orgId = clientOrgs.orgsClient.orgs[0].id;

      return (
        <OrgAwareRedirect
          toUrl={Routes.href(orgId, false, Routes.NEW_PROJECT, {
            attribution,
            params,
          })}
        />
      );
    }
    // If user is unauthenticated, redirect to signup/login
    return redirectToSignup ? (
      <Redirect
        to={Routes.SIGNUP.getUrl({
          attribution,
          params: {
            redirectTo: `${window.location.pathname}${encodeQueryParams(
              params,
            )}${window.location.hash}`,
          },
        })}
      />
    ) : (
      <Redirect
        to={Routes.LOGIN.getUrl({
          attribution,
          params: {
            redirectTo: `${window.location.pathname}${encodeQueryParams(
              params,
            )}${window.location.hash}`,
          },
        })}
      />
    );
  },
);

export default NewProjectRouteRedirect;
