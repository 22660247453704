import { NoAttribution } from "@hex/common";
import React from "react";
import { Route, Switch, useLocation } from "react-router";
import styled from "styled-components";

import { WorkspacePickerPage } from "../components/auth/WorkspacePickerPage";
import { BackgroundDiv } from "../components/workspace-setup/WorkspaceSetupSharedStyles";

import { Routes, useQueryParams } from "./routes";

export const PickerContainer = styled(BackgroundDiv)`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 30px 50px;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.MOBILE}px`}) {
    padding: 30px 0;
  }
`;

export const WorkspacePickerRoute: React.ComponentType = React.memo(
  function WorkspacePickerRoute() {
    const location = useLocation();
    const queryParams = useQueryParams();

    // TODO(VELO-4838): Replace this
    const email = queryParams.get("email") ?? undefined;

    return (
      <PickerContainer>
        <Switch location={location}>
          <Route
            exact={true}
            path={Routes.WORKSPACE_PICKER.getUrl({
              attribution: NoAttribution,
              subview: "signUp",
            })}
          >
            <WorkspacePickerPage email={email} isSignup={true} />
          </Route>
          <Route
            exact={true}
            path={Routes.WORKSPACE_PICKER.getUrl({
              attribution: NoAttribution,
              subview: "login",
            })}
          >
            <WorkspacePickerPage email={email} isSignup={false} />
          </Route>
        </Switch>
      </PickerContainer>
    );
  },
);

export default WorkspacePickerRoute;
