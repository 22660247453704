import {
  PromoCode,
  RESERVED_SIGNUP_ROUTES,
  SignupErrorReason,
} from "@hex/common";
import React, { useMemo } from "react";
import { useRouteMatch } from "react-router";

import { NavigationBar } from "../components/app/NavigationBar";
import { LoginPage } from "../components/login/LoginPage";
import { MarketingAndTestimonials } from "../components/signup/MarketingAndTestimonials";
import { PromoSignupView } from "../components/signup/PromoSignupView";
import { SignupView } from "../components/signup/SignupView";

import { QueryParams, Routes, useQueryParams } from "./routes";

export const SignupRoute: React.ComponentType = React.memo(
  function SignupRoute() {
    const queryParams = useQueryParams();
    const failed = queryParams.get("failed") as SignupErrorReason;
    const invited = queryParams.get("invited") === "true";
    const redirectTo = queryParams.get("redirectTo");
    const selectedPlanFromPricing = queryParams.get("plan");
    const link = queryParams.get("link");

    // Let's eventually deprecate this for "partner" below
    const from = queryParams.get("from");
    const partner = queryParams.get("partner");

    const fromMarketingSite = queryParams.get("source") != null;
    const showMarketingContent =
      !fromMarketingSite && !invited && failed == null;

    const paramMatch: { promo?: string } =
      useRouteMatch(Routes.SIGNUP.path)?.params ?? {};
    // Only sent promo code if value is not part of reserved signup routes
    const cleanedPromoCode = paramMatch.promo?.trim().toLowerCase();
    const promoCode =
      cleanedPromoCode && !RESERVED_SIGNUP_ROUTES.includes(cleanedPromoCode)
        ? (cleanedPromoCode as PromoCode)
        : undefined;

    const signupView = useMemo(() => {
      const authQueryParams: QueryParams = {};
      if (link) {
        authQueryParams["link"] = link;
      }
      if (redirectTo) {
        authQueryParams["redirectTo"] = redirectTo;
      }
      if (promoCode) {
        return (
          <PromoSignupView
            authQueryParams={authQueryParams}
            failed={failed}
            promoCode={promoCode}
          />
        );
      } else {
        return (
          <SignupView
            authQueryParams={authQueryParams}
            failed={failed}
            invited={invited}
            selectedPlanFromPricing={selectedPlanFromPricing}
          />
        );
      }
    }, [link, redirectTo, promoCode, failed, invited, selectedPlanFromPricing]);

    return (
      <>
        <NavigationBar isInApp={false} />
        <LoginPage
          embedded={true}
          rightElement={
            showMarketingContent ? (
              <MarketingAndTestimonials partnerLink={partner || from} />
            ) : null
          }
        >
          {signupView}
        </LoginPage>
      </>
    );
  },
);
