import React from "react";

import { LoginPage } from "../components/login/LoginPage";
import { MagicLinkSentView } from "../components/signup/MagicLinkSentView";

import { LoginListener } from "./LoginListener";
import { useQueryParams } from "./routes";

export const MagicLinkSentRoute: React.ComponentType = React.memo(
  function MagicLinkSentRoute() {
    const queryParams = useQueryParams();
    const email = queryParams.get("email") ?? "";

    return (
      <LoginPage embedded={true}>
        <LoginListener />
        <MagicLinkSentView email={email} />
      </LoginPage>
    );
  },
);
