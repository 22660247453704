import { OrgId } from "@hex/common";
import React from "react";
import { Literal, Optional, Record as RRecord, Static } from "runtypes";

import { useListenToBroadcastChannel } from "../hooks/useListenToBroadcastChannnel";
import {
  onLoginCallback,
  useLoginEventListener,
} from "../hooks/useLoginEventListener.js";
import { getUrlOrg } from "../orgs";

import { useQueryParams } from "./routes";

export const LoginEvent = RRecord({
  source: Literal("login"),
  orgId: Optional(OrgId),
});
export type LoginEvent = Static<typeof LoginEvent>;

export const LoginListener: React.ComponentType = React.memo(
  function LoginListener() {
    const queryParams = useQueryParams();
    const urlOrgId = getUrlOrg();

    const onLogin = onLoginCallback(queryParams, urlOrgId);
    useListenToBroadcastChannel("login", onLogin);

    useLoginEventListener();

    return null;
  },
);
