/**
 * This is a seperate file to help with code splitting
 */

export const DEFAULT_SPLIT_WIDTH = "50%";

export function buildDocumentTitle(
  name: string,
  projectEditable: boolean,
): string {
  return `${name}${projectEditable ? " (Editing)" : ""}`;
}
