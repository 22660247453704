import {
  CellId,
  CellReferencesV2,
  DOCS_LINKS,
  GLOBAL_REACTIVE_PARAM_TYPES,
  GraphNodeV3,
  InputParamInfoV3,
  OutputParamInfoV3,
  ParamReference,
  ReactiveParamType,
  ScopeItemType,
  SourceLocation,
  StoredCellReferencesV3,
  StoredPythonReferencesV3,
  VariableName,
  getGraphNodeV3Inputs,
  getGraphNodeV3Outputs,
  notEmpty,
} from "@hex/common";
import { escape } from "lodash";
import { editor as Editor, Range, Token, Uri } from "monaco-editor";

import { ScopeItemFragment } from "../../appsession-multiplayer/AppSessionMPModel.generated";
import { KERNEL_COMPLETION_PROVIDER } from "../../components/cell/monaco/KernelCompletionProvider";
import { TERMINOLOGY } from "../../hooks/useTerminologyText";
import { CellMP } from "../../redux/slices/hexVersionMPSlice";
import {
  ProjectSearchState,
  SearchTermDecoration,
} from "../../redux/slices/logicViewSlice.js";

const WARNING_CODE_SCOPE_TYPES: ScopeItemType[] = [
  ScopeItemType.REMOTE_DATAFRAME,
];

export const matchAndFilter = (
  model: Editor.IModel,
  tokens: Token[][],
  name: string,
): Editor.FindMatch[] => {
  return (
    model
      .findMatches(`${name}\\b`, false, true, true, null, true)
      // Filter out things that aren't variables, like in a string
      .filter((match) => {
        return (
          match.range.startLineNumber - 1 < tokens.length &&
          tokens[match.range.startLineNumber - 1].some((token) => {
            // Monaco matches appear to be 1-indexed, while tokens are 0 indexed.
            // We also don't check for index equality here because a match may include multiple tokens,
            // so we want to include the whole range if so
            const inMatchedRange =
              token.offset >= match.range.startColumn - 1 &&
              token.offset <= match.range.endColumn - 1;
            return (
              inMatchedRange &&
              (token.type === "identifier.python" ||
                token.type === "identifier.sql")
            );
          })
        );
      })
  );
};

const getRangeForLocation = ({
  loc,
  param,
}: {
  loc: SourceLocation;
  param: VariableName;
}): Range => {
  // Monaco ranges are 1-indexed, while SourceLocation is 0-indexed
  return new Range(
    loc.line + 1,
    loc.column + 1,
    loc.line + 1,
    loc.column + 1 + (loc.length ?? param.length),
  );
};

const getSourceLocationString = (loc: SourceLocation): string => {
  return `${loc.line},${loc.column}`;
};

export type HighlightContext = "db_sql" | "df_sql" | "code";

const getTooltipForCell = ({
  context,
  inputParamInfo,
  isNew,
  isReference,
  outputParamInfo,
  param,
  scope,
  suppressUndefinedReferences,
  unidfQueryMode,
}: {
  param: VariableName;
  scope: ScopeItemFragment | undefined;
  inputParamInfo: InputParamInfoV3 | undefined;
  outputParamInfo: OutputParamInfoV3 | undefined;
  isNew: boolean;
  isReference: boolean;
  context: HighlightContext;
  suppressUndefinedReferences: boolean;
  unidfQueryMode: boolean;
}): Editor.IModelDecorationOptions => {
  let scopeType: ScopeItemType = scope?.type ?? ScopeItemType.TBD;

  if (inputParamInfo?.type === ReactiveParamType.REMOTE_QUERY_RESULT) {
    scopeType = ScopeItemType.REMOTE_DATAFRAME;
  } else if (inputParamInfo?.type === ReactiveParamType.QUERY_RESULT) {
    scopeType = ScopeItemType.DATAFRAME;
  } else if (inputParamInfo?.type === ReactiveParamType.TABLE) {
    scopeType = ScopeItemType.DB_TABLE;
  }
  const lines = [];
  const classNames = ["kernel", context, scopeType.toLowerCase()];
  if (
    context !== "db_sql" &&
    WARNING_CODE_SCOPE_TYPES.includes(scopeType) &&
    !unidfQueryMode
  ) {
    lines.push(
      "Warning, this is a preview of a query and should not be used in code. You can either load this query as a dataframe, or return a subset of its data in a new SQL cell.",
    );
    classNames.push("error");
  }
  if (scope) {
    lines.push(`(${scope.rawType}) ${param}`);
    lines.push(`Preview: ${scope.displayValue}`);
  } else {
    lines.push(`${param}`);
  }
  // Only show reference param stuff if we have inputParamInfo, otherwise it means the graph is still computing
  if (isReference && inputParamInfo) {
    let parentCellId = null;
    // TODO: better way to determine if the node is actually a cell
    if (
      ![
        ReactiveParamType.SECRET,
        ReactiveParamType.AUTOMATIC_PARAM,
        ReactiveParamType.TABLE,
      ].includes(inputParamInfo.type)
    ) {
      parentCellId = inputParamInfo.parentNodeId;
    }
    const type = inputParamInfo.type;
    if (parentCellId) {
      const commandUri = Uri.parse(
        `command:selectCell?${encodeURIComponent(
          JSON.stringify({ cellId: parentCellId }),
        )}`,
      );
      if (isNew) {
        lines.push(`Updating value in place`);
      }
      lines.push(`[Go to defining cell](${commandUri})`);
    } else {
      if (type === ReactiveParamType.AUTOMATIC_PARAM) {
        lines.push("Built-in variable");
      } else if (type === ReactiveParamType.SECRET) {
        lines.push("Secret value");
      } else if (type === ReactiveParamType.TABLE) {
        const commandUri = Uri.parse(
          `command:viewTableInSchemaBrowser?${encodeURIComponent(
            JSON.stringify({
              table: param,
              dataConnectionId: inputParamInfo.dataConnectionId,
            }),
          )}`,
        );
        lines.push(
          `[Search for table in ${TERMINOLOGY.dataBrowserText}](${commandUri})`,
        );
      } else if (!suppressUndefinedReferences) {
        // If there is no parentCellId (and it's not a global) it means that this variable has never been defined above and counts as missing definition
        lines.push(`Missing definition`);
        classNames.push("error");
      }
    }
  }
  if (isNew && outputParamInfo) {
    const { type } = outputParamInfo;
    if (GLOBAL_REACTIVE_PARAM_TYPES.includes(type)) {
      if (type === ReactiveParamType.SECRET) {
        lines.push(`Redefining secret value`);
      } else if (type === ReactiveParamType.AUTOMATIC_PARAM) {
        lines.push(`Redefining built-in value`);
      } else {
        lines.push(`Redefining global static value`);
      }
      classNames.push("error");
    }
  }
  return {
    inlineClassName: classNames.join(" "),
    hoverMessage: {
      isTrusted: true,
      value: lines.join("\n\n"),
    },
    stickiness: Editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
    inlineClassNameAffectsLetterSpacing: true,
  };
};

interface NormalizedSourceLocation {
  param: VariableName;
  isNew: boolean;
  isReference: boolean;
  loc: SourceLocation;
}

function getParamSetMap(
  references: readonly ParamReference[],
): Map<VariableName, Set<string> | undefined> {
  const map: Map<VariableName, Set<string> | undefined> = new Map();
  for (const ref of references) {
    map.set(ref.param, new Set(ref.locations.map(getSourceLocationString)));
  }
  return map;
}

function normalizeReferences(
  references: CellReferencesV2,
): NormalizedSourceLocation[] {
  const newParamMap = getParamSetMap(references.newParams);
  const referencedParamMap = getParamSetMap(references.referencedParams);
  const normalizedReferences: NormalizedSourceLocation[] = [];
  references.newParams.forEach(({ locations, param }) => {
    const referencedLocations = referencedParamMap.get(param);
    normalizedReferences.push(
      ...locations.map((loc) => ({
        loc,
        param,
        isNew: true,
        isReference:
          referencedLocations?.has(getSourceLocationString(loc)) ?? false,
      })),
    );
  });
  references.referencedParams.forEach(({ locations, param }) => {
    const newLocations = newParamMap.get(param);
    normalizedReferences.push(
      ...locations
        .map((loc): NormalizedSourceLocation | null => {
          // The above newParams.forEach is in charge of adding the locations for if its both new + referenced
          // so if we encounter a reference that is also new in this location, skip it instead
          if (newLocations?.has(getSourceLocationString(loc))) {
            return null;
          }
          return {
            loc,
            param,
            isNew: false,
            isReference: true,
          };
        })
        .filter(notEmpty),
    );
  });
  return normalizedReferences;
}

export const updateHighlights = ({
  cellId,
  cellReferencesArray,
  context,
  graphNode,
  language,
  model,
  oldHighlights,
  projectSearchState,
  refScopes,
  suppressUndefinedReferences = false,
  unidfQueryMode,
}: {
  graphNode?: GraphNodeV3<CellMP>;
  cellReferencesArray?: StoredCellReferencesV3[];
  refScopes?: Record<string, ScopeItemFragment | undefined>;
  language: string;
  model: Editor.IModel;
  oldHighlights: string[];
  context: HighlightContext;
  suppressUndefinedReferences?: boolean;
  unidfQueryMode: boolean;
  projectSearchState: ProjectSearchState | null;
  cellId: CellId;
}): string[] => {
  if (model.isDisposed()) {
    return [];
  }
  // TODO(INFTY-602): Migrate to `IEditor.createDecorationsCollection` API
  const allDecorations: Editor.IModelDeltaDecoration[] = [];
  if (cellReferencesArray) {
    const cellErrors = cellReferencesArray.flatMap(
      (ref: StoredCellReferencesV3) => {
        if (!StoredPythonReferencesV3.guard(ref)) {
          return [];
        }

        return (ref.errors ?? []).map((error) => {
          const loc = error.location;
          return {
            // Monaco ranges are 1-indexed, but SourceLocations are 0-indexed
            range: new Range(
              loc.line + 1,
              loc.column + 1,
              loc.line + 1,
              loc.column + (loc.length ?? 1),
            ),
            options: {
              inlineClassName: "error",
              hoverMessage: {
                value: escape(`${error.errorType}: ${error.description}`),
              },
              stickiness:
                Editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
            },
          };
        });
      },
    );
    allDecorations.push(...cellErrors);

    allDecorations.push(
      ...cellReferencesArray
        .flatMap((cellReferences) => normalizeReferences(cellReferences))
        .map(({ isNew, isReference, loc, param }) => {
          const scope = refScopes?.[param];
          const inputInfo =
            graphNode != null
              ? getGraphNodeV3Inputs(graphNode).get(param)
              : undefined;
          const outputInfo =
            graphNode != null
              ? getGraphNodeV3Outputs(graphNode).get(param)
              : undefined;
          return {
            range: getRangeForLocation({ loc, param }),
            options: getTooltipForCell({
              param,
              scope,
              inputParamInfo: inputInfo,
              outputParamInfo: outputInfo,
              isReference,
              isNew,
              context,
              suppressUndefinedReferences,
              unidfQueryMode,
            }),
          };
        }),
    );

    if (language === "python") {
      const globalsDecorations: Editor.IModelDeltaDecoration[] = model
        .findMatches("globals()", false, false, true, null, false)
        .map((match) => ({
          range: match.range,
          options: {
            inlineClassName: "error",
            hoverMessage: {
              value: `Warning: The usage of \`globals()\` in Hex can have unintended side effects and is not recommended. [Learn more.](${DOCS_LINKS.CellLinks})`,
            },
            stickiness:
              Editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
          },
        }));
      allDecorations.push(...globalsDecorations);
    }
  } else {
    if (language !== "markdown") {
      const tokens = Editor.tokenize(model.getValue(), language);
      const parameterDecorations: Editor.IModelDeltaDecoration[] = [];

      const kernelDecorations: Editor.IModelDeltaDecoration[] =
        KERNEL_COMPLETION_PROVIDER.getCompletions().flatMap(
          ({ name, type }) => {
            return matchAndFilter(model, tokens, `(^|(?<!\\$))${name}`)
              .map((match) => [
                {
                  range: match.range,
                  options: {
                    inlineClassName: `kernel ${type.toLowerCase()}`,
                    inlineClassNameAffectsLetterSpacing: true,
                    stickiness:
                      Editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
                  },
                },
              ])
              .flat();
          },
        );

      const nbspDecorations: Editor.IModelDeltaDecoration[] = model
        .findMatches("\xa0", false, false, false, null, false)
        .map((match) => ({
          range: match.range,
          options: {
            inlineClassName: "error",
            hoverMessage: {
              value:
                "nbsp characters can cause errors in python and bash because they are not normal spaces",
            },
            stickiness:
              Editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
          },
        }));

      allDecorations.push(
        ...parameterDecorations,
        ...kernelDecorations,
        ...nbspDecorations,
      );
    }
  }
  // Add search term highlighting for the project
  if (projectSearchState != null) {
    const {
      activeHoverItem,
      caseMatch,
      projectSearchTerm,
      selectedSearchItem,
    } = projectSearchState;

    const searchTermDecorations: Editor.IModelDeltaDecoration[] = model
      .findMatches(projectSearchTerm, false, false, caseMatch, null, true)
      .map((match) => ({
        range: match.range,
        options: {
          inlineClassName: `highlighted-search-word-match`,
          stickiness: Editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
        },
      }));
    allDecorations.push(...searchTermDecorations);

    const findFocusedSearchMatch =
      selectedSearchItem && selectedSearchItem?.cellId === cellId;
    const findHoverSearchMatch =
      activeHoverItem && activeHoverItem?.cellId === cellId;

    const addSearchTermDecoration = (item: SearchTermDecoration) => {
      const searchTermDecoration: Editor.IModelDeltaDecoration = {
        range: new Range(
          item.lineIndex,
          // for Monaco: indexing starts at 1
          item.match.startIndex + 1,
          item.lineIndex,
          // for Monaco: indexing starts at 1
          item.match.endIndex + 1,
        ),
        options: {
          inlineClassName: `highlighted-search-word-focused`,
          stickiness: Editor.TrackedRangeStickiness.NeverGrowsWhenTypingAtEdges,
        },
      };
      allDecorations.push(searchTermDecoration);
    };

    if (findFocusedSearchMatch) {
      addSearchTermDecoration(selectedSearchItem);
    }

    if (findHoverSearchMatch) {
      addSearchTermDecoration(activeHoverItem);
    }
  }
  return model.deltaDecorations(oldHighlights, allDecorations);
};
